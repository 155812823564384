import React, {FC} from 'react';
import {Box, BoxProps} from '@chakra-ui/react';
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

interface LineGraphProps {
	data: any[];
	xAxisKey: string;
	yAxes?: Array<{
		id: string;
		orientation: 'left' | 'right';
	}>;
	lines: Array<{
		yAxisId?: string;
		dataKey: string;
		color: string;
		label?: string;
	}>;
}

const LineGraph: FC<LineGraphProps & BoxProps> = ({data, xAxisKey, yAxes, lines, ...boxProps}) => (
	<Box {...boxProps} bgColor='white'>
		<ResponsiveContainer width='100%' height='100%'>
			<LineChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 25,
					bottom: 25,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey={xAxisKey} />
				{yAxes?.length ? (
					yAxes.map((y) => (
						<YAxis key={y.id} yAxisId={y.id} orientation={y.orientation} />
					))
				) : (
					<YAxis />
				)}
				<Tooltip />
				<Legend />
				{lines.map((line) => (
					<Line
						key={line.dataKey}
						type='monotone'
						dataKey={line.dataKey}
						name={line.label || line.dataKey}
						stroke={line.color}
						strokeWidth={3}
						activeDot={{r: 8}}
						yAxisId={line.yAxisId}
						connectNulls
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	</Box>
);

export default LineGraph;

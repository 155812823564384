import React from 'react';
import {Box, BoxProps} from '@chakra-ui/react';

interface BodyContainerProps extends BoxProps {
	children: React.ReactNode;
}

const BodyContainer = ({children, ...rest}: BodyContainerProps) => (
	<Box
		w='auto'
		bgColor='white'
		borderRadius='lg'
		border='solid 1px'
		borderColor='gray.100'
		{...rest}
	>
		{children}
	</Box>
);

export default BodyContainer;

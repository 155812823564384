import React from 'react';
import {Box, Heading, HStack} from '@chakra-ui/react';
import themeColor from '../utils/themeColors';
import NotificationBell from './NotificationBell';

interface TopMenuProps {
	children: React.ReactNode;
}

const TopMenu = ({children}: TopMenuProps) => (
	<Box
		w='auto'
		bgColor='white'
		borderRadius='lg'
		mt={4}
		mr={4}
		p={4}
		border='solid 1px'
		borderColor='gray.100'
	>
		<HStack w='full' justifyContent='space-between'>
			<Heading size='md' color={`${themeColor()}.700`}>
				{children}
			</Heading>
			<Box>
				<NotificationBell top={-4} right={4} />
			</Box>
		</HStack>
	</Box>
);

export default TopMenu;

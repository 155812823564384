import React from 'react';
import {Box, BoxProps, Fade, Heading, IconButton, Portal, Slide} from '@chakra-ui/react';
import {MdClose} from 'react-icons/md';
import {useWindowSize} from 'react-use';

interface SidePanelProps extends BoxProps {
	children: React.ReactNode;
	title: string;
	isOpen: boolean;
	onClose: () => void;
	size?: 'half' | 'full';
}

const SidePanel = ({children, title, size = 'half', isOpen, onClose, ...rest}: SidePanelProps) => {
	const [numberOfSidePanelsInDom, setNumberOfSidePanelsInDom] = React.useState(
		document.querySelectorAll('.side-panel-container').length,
	);

	const xOffSet = numberOfSidePanelsInDom * 48;

	const {width} = useWindowSize();

	const getSidePanelWidth = () => {
		if (size === 'full' || width < 768) {
			return '100%';
		}

		if (width < 1024) {
			return `${width * 0.8 - xOffSet}px`;
		}

		return `${width * 0.66 - xOffSet}px`;
	};

	React.useEffect(() => {
		setNumberOfSidePanelsInDom(document.querySelectorAll('.side-panel-container').length);
	}, [isOpen]);

	return (
		<Portal>
			<Fade in={isOpen} unmountOnExit>
				<Box pos='fixed' top={0} left={0} w='100%' h='100%' bg='rgba(0,0,0,0.2)' />
			</Fade>
			<Slide direction='right' in={isOpen} style={{zIndex: 'sticky'}} unmountOnExit>
				<Box
					className='side-panel-container'
					zIndex='sticky'
					pos='fixed'
					right={0}
					top={0}
					bottom={0}
					w={getSidePanelWidth()}
					bgColor='white'
					boxShadow='lg'
					p={4}
					overflowY='auto'
					{...rest}
				>
					<Heading w='full' size='md' textAlign='left'>
						{title}
						<IconButton
							aria-label='close'
							icon={<MdClose />}
							variant='ghost'
							float='right'
							onClick={onClose}
						/>
					</Heading>
					<Box w='full' mt={4}>
						{children}
					</Box>
				</Box>
			</Slide>
		</Portal>
	);
};

export default SidePanel;

const themeColor = () => {
	const path = window.location.pathname;

	if (path.includes('admin')) {
		return 'gray';
	}

	if (path.includes('employer')) {
		return 'blue';
	}

	if (path.includes('provider')) {
		return 'teal';
	}

	if (path.includes('pharmacy')) {
		return 'purple';
	}

	return 'gray';
};

export default themeColor;
